/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled';
import { Swiper } from 'swiper/react';

import { ContentWrapper, fontSize } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const MainBanner = styled.section`
  width: 100%;
  height: 582px;
  position: relative;

  @media screen and ${breakpoint.lg} {
    height: unset;

    h1 {
      font-size: ${fontSize(48)};
    }
  }

  @media screen and ${breakpoint.md} {
    h1 {
      font-size: ${fontSize(32)};
    }
  }
`;

export const StyledSwiper = styled(Swiper)`
  height: 100%;
`;

export const SlideWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;

  @media screen and ${breakpoint.lg} {
    display: block;
  }
`;

export const LeftSide = styled.div`
  margin-top: -100px;
  z-index: 2;
  a.mobile {
    display: none;
  }
  @media screen and ${breakpoint.lg} {
    margin: 15px 0;
    a.desktop {
      display: none;
    }
    a.mobile {
      display: inline;
      margin-left: 10px;
      font-size: ${fontSize(20)};
    }
    p {
      font-size: ${fontSize(20)};
      margin: 20px 0 30px;
      display: inline-block;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 582px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  .image {
    height: 100% !important;
    right: -250px !important;
  }

  & > span {
    display: block;
    height: 100% !important;
    padding-top: 0 !important;
    position: absolute;
    top: 0;
    right: -250px;

    span {
      padding-top: 0 !important;
    }

    img {
      max-height: 582px;
    }
  }

  @media only screen and (max-width: 1300px) {
    & > span {
      right: -440px;
    }
  }

  @media only screen and ${breakpoint.lg} {
    position: static;
    height: 100%;
    width: 100%;

    & > span {
      right: 0;
    }

    .image {
      position: static !important;
      width: 100%;
    }
  }
`;

export const Text = styled.p`
  color: #004660;
  font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};
  font-size: ${fontSize(32)};
  letter-spacing: -0.48px;
  text-align: left;
  color: #004660;
  margin: 30px 0 50px;
`;
