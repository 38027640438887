/* eslint-disable import/no-unresolved */
import React, { memo } from 'react';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { get } from 'lodash';

import Button, { BUTTON_THEME } from '@components/Button';
import TextHeader, { SIZE, WEIGHT } from '@components/TextHeader';

import {
  MainBanner as Banner,
  LeftSide,
  ImageContainer,
  Text,
  StyledSwiper,
  SlideWrapper,
} from '@components/PageBanner/styles';

import { useAppContext } from '@context/AppContext';
import HighContrastBackground from '@assets/images/hero-ludziki-highcontrast.svg';

type MainBannerProps = {
  bannerItems: BannerItem[];
};

function MainBanner({ bannerItems }: MainBannerProps) {
  const { useHighContrastTheme: isHighContrast } = useAppContext();
  if (!bannerItems || bannerItems.length === 0) {
    return null;
  }

  return (
    <Banner>
      <StyledSwiper
        spaceBetween={30}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[EffectFade, Autoplay]}
        className="mySwiper"
      >
        {bannerItems.map((item: BannerItem) => (
          <SwiperSlide key={item.id}>
            <SlideWrapper>
              <LeftSide>
                <TextHeader size={SIZE.XL} weight={WEIGHT.BOLD}>
                  {item.title}
                </TextHeader>
                <Text>{item.description}</Text>

                <Button className="desktop" href={item.link}>
                  {item.cta}
                </Button>
                <Button className="mobile" href={item.link} buttonTheme={BUTTON_THEME.TEXT}>
                  &raquo;
                </Button>
              </LeftSide>
              <ImageContainer>
                <Image
                  src={
                    isHighContrast
                      ? get(item, 'highContrastImage.desktop') || HighContrastBackground.src
                      : item.image.desktop
                  }
                  alt=""
                  layout="responsive"
                  width="100%"
                  height="100%"
                  className="image"
                />
              </ImageContainer>
            </SlideWrapper>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Banner>
  );
}

export default memo(MainBanner);
