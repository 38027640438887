import React, { useEffect, useState, useCallback, memo } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Wrapper, Accessories, OffersCounter, ClearButton, ClearButtonIcon } from '@components/OffersFilters/styles';
import AsyncDropdown from '@components/Form/Dropdown/AsyncDropdown';
import SimpleDropdown from '@components/Form/SimpleDropdown';

import { PRACTICE_TYPE, WORK_MODEL } from '@constants/offerFilters';
import { API, APIRoutes } from '@utils/api';
import { isObjectEmpty, enumToArray } from '@utils/dataHelpers';

import resetBtn from '@assets/images/icons-close.svg';

interface OffersFiltersProps {
  onSubmit: (query: any) => Promise<void> | void;
  offersCount: number;
  resetable?: boolean;
}

function OffersFilters({ onSubmit, offersCount, resetable }: OffersFiltersProps) {
  const { t } = useTranslation(['common', 'offers']);
  const {
    query: { highschoolProfile, industry, workModel, practiceType },
  } = useRouter();

  const [showResetButton, setShowResetButton] = useState(
    isObjectEmpty({ highschoolProfile, industry, workModel, practiceType })
  );

  const fetchFieldsOfStudy = async (): Promise<OptionType[]> => {
    const {
      data: { highschoolProfiles },
    } = await API.get(APIRoutes.highschoolProfiles);
    return [{ id: '', name: t('All') }, ...highschoolProfiles];
  };

  const fetchFieldsOfExpertise = async (): Promise<OptionType[]> => {
    const {
      data: { fieldsOfExpertises },
    } = await API.get(APIRoutes.fieldsOfExpertises);
    return [{ id: '', name: t('All') }, ...fieldsOfExpertises];
  };

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      offer: {
        ...(industry ? { industry: parseInt(industry.toString(), 10) } : {}),
        ...(workModel ? { workModel } : {}),
        ...(practiceType ? { practiceType } : {}),
        ...(highschoolProfile ? { highschoolProfile: parseInt(highschoolProfile.toString(), 10) } : {}),
      },
    },
  });
  const [practiceTypes, setPracticeTypes] = useState([]);
  const [model, setModel] = useState([]);

  useEffect(() => {
    setPracticeTypes(enumToArray(PRACTICE_TYPE, t));
    setModel(enumToArray(WORK_MODEL, t));
  }, [t]);

  const resetFilters = useCallback(() => {
    setValue('offer.highschoolProfile', null);
    setValue('offer.industry', null);
    setValue('offer.workModel', null);
    setValue('offer.practiceType', null);
  }, [setValue]);

  useEffect(() => {
    const subscription = watch(({ offer }) => {
      setShowResetButton(isObjectEmpty(offer));
      return handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return (
    <>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="offer.highschoolProfile"
          render={({ field: { name: inputName, onChange, value } }) => (
            <AsyncDropdown
              loadOptions={fetchFieldsOfStudy}
              id="fieldsOfExpertise"
              name={inputName}
              onChange={onChange}
              value={value}
              label={t('Studies', { ns: 'offers' })}
              type="standalone"
              placeholder={t('Select', { ns: 'common' })}
            />
          )}
        />
        <Controller
          control={control}
          name="offer.workModel"
          render={({ field: { onChange, value } }) => (
            <SimpleDropdown
              value={value}
              instanceId={t('Employment type', { ns: 'offers' })}
              onChange={onChange}
              dropdown={model}
            />
          )}
        />
        <Controller
          control={control}
          name="offer.practiceType"
          render={({ field: { onChange, value } }) => (
            <SimpleDropdown
              value={value}
              instanceId={t('Offer type', { ns: 'offers' })}
              onChange={onChange}
              dropdown={practiceTypes}
            />
          )}
        />
        <Controller
          control={control}
          name="offer.industry"
          render={({ field: { name: inputName, onChange, value } }) => (
            <AsyncDropdown
              loadOptions={fetchFieldsOfExpertise}
              id="fieldOfExpertise"
              name={inputName}
              onChange={onChange}
              value={value}
              label={t('Industry', { ns: 'offers' })}
              type="standalone"
              placeholder={t('Select', { ns: 'common' })}
            />
          )}
        />
      </Wrapper>
      <Accessories>
        <OffersCounter>{t('Offers count', { count: offersCount, ns: 'offers' })}</OffersCounter>

        {resetable && showResetButton ? (
          <ClearButton type="reset" onClick={resetFilters}>
            <ClearButtonIcon>
              <Image src={resetBtn} />
            </ClearButtonIcon>
            {t('Clear filters', { ns: 'offers' })}
          </ClearButton>
        ) : null}
      </Accessories>
    </>
  );
}

OffersFilters.defaultProps = {
  resetable: false,
};

export default memo(OffersFilters);
