import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'next-i18next';

import { StyledSelect, Label, customStyles } from '@components/Form/SimpleDropdown/styles';
import { useAppContext } from '@context/AppContext';

export type OptionProps = {
  id: number;
  label: string;
  value: string;
};

type SimpleDropdownProps = {
  dropdown: any;
  instanceId: string;
  value: string | string[] | number;
  onChange: (option: string) => void;
  onBlur?: (...event: []) => void;
};

function SimpleDropdown({ dropdown, instanceId, value, onChange, onBlur }: SimpleDropdownProps) {
  const { t } = useTranslation('common');
  const { useHighContrastTheme } = useAppContext();

  return (
    <StyledSelect highContrast={useHighContrastTheme} className="highcontrast-select">
      <Label htmlFor={instanceId}>{instanceId}</Label>
      <Select
        id={instanceId}
        instanceId={instanceId}
        styles={customStyles}
        value={value !== null ? dropdown.find((x) => x?.value === value) : ''}
        placeholder={t('Select')}
        onChange={(option: OptionProps) => {
          onChange(option.value);
        }}
        classNamePrefix="simple-dropdown"
        options={dropdown}
        onBlur={onBlur}
      />
    </StyledSelect>
  );
}

SimpleDropdown.defaultProps = {
  onBlur: null,
};

export default SimpleDropdown;
